<template>
  <div class="aaaa">
    <div class="anniu" @click="change()">
      服务人员表
    </div>
    <TWOpage v-show="senced"></TWOpage>
    <div class="contontaa" v-show="once">

      <div class="echart1">
        <div class="text">
          服务平台收益
        </div>
       <TOPECHARTS></TOPECHARTS>
      </div>
      <div class="echart2">
        <RIGHTECHARTS></RIGHTECHARTS>
      </div>
    </div>
  </div>

</template>

<script>
  import TOPECHARTS from "../rightecharts/topecharts.vue"
  import RIGHTECHARTS from "../rightecharts/rightzhuecharts.vue"
  import TWOpage from "../TWOpage/twopage.vue"
  export default {
    components: {
      TOPECHARTS,
      RIGHTECHARTS,
      TWOpage
    },
    data() {
      return {
        once:false,
        senced: true
      }
    },
    methods: {
      change() {
        this.once =  !this.once 
        this.senced = !this.senced
      }
    }
  }
</script>

<style scoped>
  .contontaa {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-image: url('../../assets/img/ciji3.png');

  }

  .echart1 {
    width: 100%;
    height: 70%;
    background-image: url('../../assets/img/cjii5.png');
  }

  .echart1 .text {
    font-size: 30px;
    color: white;
    text-align: center;
    line-height: 80px;
  }

  .echart2 {
    width: 100%;
    height: 33.3%;
    background-image: url('../../assets/img/card.png');
    background-repeat: no-repeat;
    background-size: 97% 100%;
    margin-top: 100px;
  }

  .anniu {
    width: 79px;
    padding: 10px;
    background-color: #428bca;
    border-color: #357eba;
    color: #fff;
    border-radius:10px;
    text-align: center;
    vertical-align: middle;
    border:1px solid transparent;
    font-weight: 800;
    font-size: 16px;
    position: absolute;
    top: 2%;
    right: 5%;
  }
</style>
