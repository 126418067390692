<template>
  <div class="contontaab">
    <div :style="{ width: '500px', height: '260px' }" ref="myChart"></div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        countMoney: '',
        stayMoney: '',
        sumMoney: '',
        time: '',
        list: '',
        Timer: ''
      }
    },
    methods: {

      getEchartData() {
        let myChart = this.$echarts.init(this.$refs.myChart);
        var option = {
          title: {
            text: '服务人员提现部分表',
            textAlign: 'center',
            left: '49.5%',
            textStyle: {
              color: '#ffffff',
              fontSize: 24,
              fontWeight: '600',
            },
          },
          tooltip: {},
          grid: {
            left: '8%',
            right: '4%',
            bottom: '0%',
            top: '13%',
            containLabel: true,
          },
          xAxis: {
            type: 'value',
            max: 100,
            show: true,
            // 不显示轴线
            axisLine: {
              show: false
            },
            // 不显示刻度线
            axisTick: {
              show: false,
            },
            splitLine: { // 网格线为虚线
              show: false,
            },
            axisLabel: {
              color: '#fff',
              formatter: '{value}'
            }
          },
          yAxis: {
            type: 'category',
            inverse: true,
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              interval: 0,
              color: 'rgba(255, 255, 255, 1)',
              fontSize: 14,

            },
            data: ['提现次数', '待转账次数', '累计提现金额'],
          },
          series: [{
              type: 'bar',
              barWidth: 12,
              zlevel: 2,
              barGap: '-150%',
              itemStyle: {

                normal: {
                  axisLabel: {

                    formatter: '{value}元'
                  },
                  borderRadius: [0, 6, 6, 0],
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 1,
                    colorStops: [{
                      offset: 0,
                      color: 'rgba(61, 131, 255, 0)'
                    }, {
                      offset: 1,
                      color: 'rgba(72, 240, 255, 1)'
                    }]
                  }
                },
              },
              data: this.list,
            },
            {
              type: 'bar',
              barWidth: 20,
              itemStyle: {
                normal: {
                  color: 'rgba(60, 102, 118, 0.34)',
                },
              },
              data: [500, 500, 500],
            },
          ],
        };

       let currentIndex = 0;
       clearInterval(this.timer);
       this.timer = setInterval(function() {
         // 取消之前高亮的图形
         myChart.dispatchAction({
           type: 'downplay',
           seriesIndex: 0,
         });
         // 高亮当前图形
         myChart.dispatchAction({
           type: 'highlight',
           seriesIndex: 0,
           dataIndex: currentIndex
       
         });
         // 显示 tooltip
         myChart.dispatchAction({
           type: 'showTip',
           seriesIndex: 0,
           dataIndex: currentIndex
       
         });
         currentIndex++;
         if (currentIndex > option.series[0].data.length) {
           currentIndex = 0;
         }
       }, 2000);
       myChart.clear()
       myChart.setOption(option, true);
      },
      selectPayDetails() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('/cash/statisticsCashMoney?time=' + this.time + '&flag=' + 3, {
          headers: {
            'token': Authorization
          }
        }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push('/')
              
          } else {
            var newdata = [
              this.countMoney = res.data.data.countMoney, //代
              this.stayMoney = res.data.data.stayMoney, //提现
              this.sumMoney = res.data.data.sumMoney, //总
            ]
            this.list = [...newdata]
            this.getEchartData()
          }
        })
      },
      getDate() {
        var date = new Date();
        var year = date.getFullYear(); //  返回的是年份
        var month = date.getMonth() + 1; //  返回的月份上个月的月份，记得+1才是当月
        var dates = date.getDate(); //  返回的是几号
        var hours = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();
        this.time = year + "-" + month + "-" + dates + "+" + hours + ":" + min + ":" + sec;
      }

    },
    mounted() {
      this.getEchartData()
      this.selectPayDetails()
      this.Timer = setInterval(this.selectPayDetails, 20000)
    },
    destroyed() {
      clearInterval(this.Timer)
    },
    created() {
      this.getDate()
    }
  }
</script>

<style scoped>
  .contontaab {
    z-index: 999;
    width: 6rem;
    position: relative;
    left: -0.5rem;
    top: 0.5rem;
  }
</style>