<template>
  <v-scale-screen width="1920" height="1080" :fullScreen="true ">
    <div class="contont">
      <div class="header">
        <div class="text">上门服务大数据面板</div>
        <div class="mian">

          <div class="left" style="float: left; ">
            <left></left>
          </div>

          <div class="middle">
            <middle></middle>
          </div>

          <div class="right" style="float: right;">
            <right></right>
          </div>
        </div>
      </div>

    </div>
  </v-scale-screen>
</template>

<script>
  import {
    defineComponent
  } from "vue"
  import VScaleScreen from 'v-scale-screen'
  import left from "../components/moduleleft/moduleleft.vue"
  import right from "../components/mouduleright/moduleright.vue"
  import middle from "../components/mouldmiddle/mouldmiddle.vue"
  export default {
    components: {
      right,
      left,
      middle
    },
    data() {
      return {}
    },

    mounted() {

    },
    beforeDestroy() {

    },
  }
</script>

<style scoped>
  .contont {
    width: 100%;
    height: 1080px;
    background-image: url('../assets/img/bj.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
  }

  .main {
    width: 100%;
    height: 1080px;
  }

  .header .text {
    font-size: 61px;
    background: white;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    text-align: center;
    line-height: 149px;
    font-family: 'electronicFont';
  }

  .left {
    width: 502px;
    height: 87%;
    position: absolute;
    left: 33px;
    top: 116px;
  }

  .right {
    width: 514px;
    height: 82%;
    position: absolute;
    right: 20px;
    top: 160px;

  }

  .middle {
    width: 805px;
    height: 82%;
    position: absolute;
    left: 565px;
    top: 160px;

  }
</style>
