<template>
  <div class="contontqwe">
    <div class="middle1">
      <div class="middle1_one">
        <div class="middle1_one_one">
          <div class="wenzi">总用户人数</div>
          <div class="numm">{{Num}}</div>
        </div>
        <div class="middle1_one_two"></div>
        <div class="middle1_one_three">
          <div class="wenzi">总充值</div>
          <div class="numm">{{numjine}}</div>
        </div>
      </div>
      <!-- 按钮 -->
      <!-- <div class="query">
        <a class="btn" @click="chaday(1)">按天查询</a>
        <a class="btn btn-secondary" @click="yearsday(3)"> 按年查询
        </a>
      </div> -->
      <div class="middle1_two">
        <div class="middle1_two_one">
          <div class="num">{{sumMoney}}</div>
          <div class="danwei">元</div>
          <div class="text">总收益</div>

        </div>
        <div class="middle1_two_two">
          <div class="num">{{orderMoney}}</div>
          <div class="danwei">元</div>
          <div class="text">订单金额</div>
        </div>
        <div class="middle1_two_three">
          <div class="num">{{gmhyMoney}}</div>
          <div class="danwei">元</div>
          <div class="text">购买会员金额</div>
        </div>
        <div class="middle1_two_four">
          <div class="num">{{czMoney}}</div>
          <div class="danwei">元</div>
          <div class="text">充值金额</div>
        </div>
        <div class="middle1_two_five">

          <div class="num">{{bzjMoney}}</div>
          <div class="danwei">元</div>
          <div class="text">保证金金额</div>
        </div>
      </div>
    </div>
    <div class="middle2">
      <div class="anniu">
        <a style="--clr:#1e9bff;background-color: black; opacity: 0.5;" class="anniu1"
          @click="getmiddleecharts()"><span>服务价格</span><i></i></a>
        <a style="--clr:#ff1867;background-color: black; opacity: 0.5;" class="anniu2" @click="ciyunu()"
          bac><span>订单量</span><i></i></a>
      </div>

      <middleecharts v-show="middleecharts"></middleecharts>
      <ciyun v-show="ciyun"></ciyun>
    </div>
  </div>
</template>

<script>
  import middleecharts from "../middleecharts/zoumadeng/zouamdeng.vue"
  import ciyun from "../middleecharts/ciyutubiao.vue"
  export default {
    components: {
      middleecharts,
      ciyun
    },
    data() {
      return {
        //总用户
        Num: 9999,
        //总金额收益
        numjine: 99999,
        //显示隐藏
        middleecharts: true,
        ciyun: false,
        sumMoney: "", //总收益
        orderMoney: '', //订单收益
        bzjMoney: '', //保证金收益
        czMoney: '', //充值收益
        gmhyMoney: '', //购买会员收益
        zczMoney: '', //总充值数量
        time: '', //时间,
        Timer: ''
      }
    },
    methods: {
      chaday(e) {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get("user/selectMoneyCount?date=" + this.time + '&type=' + e, {
          headers: {
            'token': Authorization
          }
        }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push('/')
              
          } else {
            this.sumMoney = res.data.data.sumMoney //总收益
            this.orderMoney = res.data.data.ordersMoney //订单收益
            this.bzjMoney = res.data.data.memberMoney //保证金收益
            this.czMoney = res.data.data.couponMoney //充值收益
            this.gmhyMoney = res.data.data.realMoney //购买会员收益
            this.zczMoney = res.data.data.money //总充值数量  
          }


        })
      },
      yearsday(e) {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get("user/selectMoneyCount?date=" + this.time + '&type=' + e, {
          headers: {
            'token': Authorization
          }
        }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push('/')
              
          } else {
            this.sumMoney = res.data.data.sumMoney //总收益
            this.orderMoney = res.data.data.ordersMoney //订单收益
            this.bzjMoney = res.data.data.memberMoney //保证金收益
            this.czMoney = res.data.data.couponMoney //充值收益
            this.gmhyMoney = res.data.data.realMoney //购买会员收益
            this.zczMoney = res.data.data.money //总充值数量  
          }


        })
      },
      getmiddleecharts() {
        this.middleecharts = false
        this.ciyun = true
      },
      ciyunu() {
        this.middleecharts = true
        this.ciyun = false
      },
      selectMoney() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get("user/selectMoneyCount?date=" + this.time + '&type=' + 3, {
          headers: {
            'token': Authorization
          }
        }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push('/')
              
          } else {
            this.sumMoney = res.data.data.sumMoney //总收益
            this.orderMoney = res.data.data.ordersMoney //订单收益
            this.bzjMoney = res.data.data.memberMoney //保证金收益
            this.czMoney = res.data.data.couponMoney //充值收益
            this.gmhyMoney = res.data.data.realMoney //购买会员收益
            this.zczMoney = res.data.data.money //总充值数量  
          }
        })
        this.$axios.get("/user/homeMessage", {
          headers: {
            'token': Authorization
          }
        }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push('/')
              
          } else {
            this.Num = res.data.data.totalUsers
            this.numjine = res.data.data.totalRevenue
          }


        })
      },
      getDate() {
        var date = new Date();
        var year = date.getFullYear(); //  返回的是年份
        var month = date.getMonth() + 1; //  返回的月份上个月的月份，记得+1才是当月
        var dates = date.getDate(); //  返回的是几号
        var hours = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();
        this.time = year + "-" + month + "-" + dates + "+" + hours + ":" + min + ":" + sec
      }

    },
    created() {
      this.getDate()
    },
    mounted() {
      this.selectMoney()
      this.Timer = setInterval(this.selectMoney, 20000)

    },
    destroyed() {
      clearInterval(this.Timer)
    }

  }
</script>

<style scoped lang="less">
  .contontqwe {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .middle1 {
    width: 100%;
    height: 50%;
    position: relative
  }

  .middle1 .middle1_one {
    width: 100%;
    height: 150px;
    text-align: center;
    position: absolute;
    left: 24px;
    top: 43px;
  }

  .middle1 .middle1_one .middle1_one_one {
    width: 40%;
    height: 135px;
    background-image: url('../../assets/img/middleimg2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: -2%;
    top: 45%
  }

  .middle1 .middle1_one .middle1_one_two {
    width: 26%;
    height: 211px;
    background-image: url('../../assets/img/middleimg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 34%;
    top: 6%;

  }

  .middle1 .middle1_one .middle1_one_three {
    width: 40%;
    height: 134px;
    background-image: url('../../assets/img/middleimg1.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 55%;
    top: 45%;
  }

  .wenzi {
    margin: 18px 0;
    font-size: 30px;
    font-weight: 700;
    color: white;
  }

  .numm {
    font-size: 35px;
    color: skyblue;
  }

  .middle1_two {
    width: 80%;
    height: 50%;
    position: absolute;
    left: 13%;
    top: 60%;
    display: flex;
    flex-wrap: wrap;

  }

  .middle1_two .middle1_two_one {
    margin-top: 0.2rem;
    width: 32%;
    height: 60%;
    background-image: url('../../assets/img/xsi.png');
    background-repeat: no-repeat;
    background-size: 100% 80%;
  }

  .middle1_two .middle1_two_one .num {
    color: skyblue;
    position: absolute;
    left: 84px;
    top: 20px;
  }

  .middle1_two .middle1_two_one .danwei {
    color: whitesmoke;
    font-size: 20px;
    position: absolute;
    left: 130px;
    top: 50px;
  }

  .middle1_two .middle1_two_one .text {
    color: white;
    font-size: 18px;
    position: absolute;
    left: 74px;
    top: 88px;
  }

  .middle1_two .middle1_two_two {
    margin-top: 0.2rem;
    width: 32%;
    height: 60%;
    background-image: url('../../assets/img/xsi.png');
    background-repeat: no-repeat;
    background-size: 100% 80%;
  }

  .middle1_two .middle1_two_two .num {
    color: skyblue;
    position: absolute;
    top: 20px;
    left: 290px;
  }

  .middle1_two .middle1_two_two .danwei {
    color: whitesmoke;
    font-size: 20px;
    position: absolute;
    left: 310px;
    top: 50px;
  }

  .middle1_two .middle1_two_two .text {
    color: white;
    font-size: 18px;
    position: absolute;
    left: 270px;
    top: 86px;
  }

  .middle1_two .middle1_two_three {
    margin-top: 0.2rem;
    width: 32%;
    height: 60%;
    background-image: url('../../assets/img/xsi.png');
    background-repeat: no-repeat;
    background-size: 100% 80%;
  }

  .middle1_two .middle1_two_three .num {
    color: skyblue;
    position: absolute;
    top: 20px;
    left: 500px;
  }

  .middle1_two .middle1_two_three .danwei {
    color: whitesmoke;
    font-size: 20px;
    position: absolute;
    left: 525px;
    top: 46px;
  }

  .middle1_two .middle1_two_three .text {
    color: white;
    font-size: 18px;
    position: absolute;
    left: 461px;
    top: 88px;
  }

  .middle1_two .middle1_two_four {
    margin-top: 0.2rem;
    width: 32%;
    height: 60%;
    background-image: url('../../assets/img/xsi.png');
    background-repeat: no-repeat;
    background-size: 100% 80%;
    margin-left: 50px;
  }

  .middle1_two .middle1_two_four .num {
    color: skyblue;
    position: absolute;
    left: 130px;
    top: 157px;
  }

  .middle1_two .middle1_two_four .danwei {
    color: whitesmoke;
    font-size: 20px;
    position: absolute;
    left: 149px;
    top: 182px;
  }

  .middle1_two .middle1_two_four .text {
    color: white;
    font-size: 18px;
    position: absolute;
    left: 7.2rem;
    top: 13.85rem;
  }

  .middle1_two .middle1_two_five {
    margin-top: 0.2rem;
    width: 32%;
    height: 60%;
    background-image: url('../../assets/img/xsi.png');
    background-repeat: no-repeat;
    background-size: 100% 80%;
    margin-left: 100px;
  }

  .middle1_two .middle1_two_five .num {
    color: skyblue;
    position: absolute;
    left: 433px;
    top: 155px;
  }

  .middle1_two .middle1_two_five .danwei {
    color: whitesmoke;
    font-size: 20px;
    position: absolute;
    left: 465px;
    top: 185px;

  }

  .middle1_two .middle1_two_five .text {
    color: white;
    font-size: 18px;
    position: absolute;
    left: 26.1rem;
    top: 13.75rem;
  }

  .middle1_two .middle1_two_six {
    margin-top: 0.2rem;
    width: 32%;
    height: 60%;
    background-image: url('../../assets/img/xsi.png');
    background-repeat: no-repeat;
    background-size: 100% 80%;
  }

  .middle1_two .middle1_two_six .num {
    color: skyblue;
    position: absolute;
    left: 5.5rem;

  }

  .middle1_two .middle1_two_six .danwei {
    color: whitesmoke;
    font-size: 0.25rem;
    position: absolute;
    left: 6.3rem;
    top: 2rem;
  }

  .middle1_two .middle1_two_six .text {
    color: white;
    font-size: 0.215rem;
    position: absolute;
    left: 5.6rem;
    top: 2.75rem;
  }

  .middle2 {
    width: 100%;
    height: 45%;
    background-image: url('../../assets/img/cjij4.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 1.5%;
    top: 60%;
    /* background-color: #fff; */
  }

  .anniu {
    width: 200px;
    display: flex;
    position: relative;
    z-index: 999;
    align-items: center;
    text-align: center;

    .anniu1 {
      width: 100px;
      padding: 10px;
      font-weight: 800;
      font-size: 14px;
      margin-right: 0.2rem;
      position: absolute;
      top: -45px;
      left: 4px;
      border: 1px solid transparent;
    }

    .anniu2 {
      width: 100px;
      padding: 10px;
      border: 1px solid transparent;
      font-weight: 900;
      font-size: 14px;
      margin-right: 0.2rem;
      position: absolute;
      top: -45px;
      left: 681px;
    }

    a {
      position: relative;
      background-color: rgb(68, 68, 68, 0.5);
      color: #fff;
      text-decoration: none;
      font-size: 0.025rem;
      letter-spacing: 0.1em;
      padding: 10px 30px;
      transition: .5s;
      border: 3px solid rgb(68, 68, 68);
    }

    a:hover {
      letter-spacing: 0.25em;
      border-color: var(--clr);
      color: var(--clr);
      box-shadow: 0 0 35px var(--clr);
    }

    a::before {
      content: '';
      position: absolute;
      inset: 2px;
    }

    s span {
      position: relative;
      z-index: 2;
      color: white;
    }

    a i {
      position: absolute;
      inset: 0;
      display: block;
    }

    /* //小框 */
    a i::before {
      content: '';
      position: absolute;
      top: -6px;
      left: 80%;
      width: 10px;
      height: 5px;
      border: 2px solid var(--clr);
      background: #27282c;
      transform: translateX(-50%);
      transition: .5s;
    }

    a:hover i::before {
      width: 20px;
      left: 20%;
    }

    a i::after {
      content: '';
      position: absolute;
      bottom: -6px;
      left: 20%;
      width: 10px;
      height: 5px;
      border: 2px solid var(--clr);
      background: #27282c;
      transform: translateX(-50%);
      transition: .5s;
    }

    a:hover i::after {
      width: 20px;
      left: 80%;
    }
  }



  .query {
    width: 2.5rem;
    display: flex;
    position: absolute;
    text-align: center;
    left: 4.4rem;
    bottom: 4.1rem;
    color: floralwhite;
    opacity: 0.5;
    z-index: 9999;

    a.btn {
      display: inline-block;
      padding: .6em 1.1em;
      font-size: 0.125rem;
      text-decoration: none;
      outline: none;
      color: #fff;
      background-color: #fe4365;
      border-radius: 3px;
      background-clip: padding-box;
      border: none;
      -webkit-transition: -webkit-box-shadow .3s;
      transition: box-shadow .3s;
    }

    a.btn:hover,
    a.btn:focus {
      -webkit-box-shadow: 0 0 0 2px #cff09e, 0 0 0 4px #ff0364;
      box-shadow: 0 0 0 2px #cff09e, 0 0 0 4px #ff0364;
      -webkit-transition-timing-function: cubic-bezier(0.6, 4, 0.3, 0.8);
      transition-timing-function: cubic-bezier(0.6, 4, 0.3, 0.8);
      -webkit-animation: gelatine 0.5s 1;
      animation: gelatine 0.5s 1;
    }

    a.btn-secondary {
      background: #c8c8a9;
      -webkit-box-shadow: 0 0 0 -2px #cff09e, 0 0 0 -1px #c8c8a9;
      box-shadow: 0 0 0 -2px #cff09e, 0 0 0 -1px #c8c8a9;
    }

    a.btn-secondary:hover {
      -webkit-box-shadow: 0 0 0 2px #cff09e, 0 0 0 4px #bebe99;
      box-shadow: 0 0 0 2px #cff09e, 0 0 0 4px #bebe99;
    }

    a.btn:active,
    a.btn-secondary:active {
      background: #4ecdc4;
      -webkit-transition-duration: 0;
      transition-duration: 0;
      -webkit-box-shadow: 0 0 0 2px #cff09e, 0 0 0 4px #3ac7bd;
      box-shadow: 0 0 0 2px #cff09e, 0 0 0 4px #3ac7bd;
    }

    /**
   * $keyframes \ gelatine 
   **/
    @keyframes gelatine {

      from,
      to {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
      }

      25% {
        -webkit-transform: scale(0.9, 1.1);
        transform: scale(0.9, 1.1);
      }

      50% {
        -webkit-transform: scale(1.1, 0.9);
        transform: scale(1.1, 0.9);
      }

      75% {
        -webkit-transform: scale(0.95, 1.05);
        transform: scale(0.95, 1.05);
      }

      from,
      to {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
      }

      25% {
        -webkit-transform: scale(0.9, 1.1);
        transform: scale(0.9, 1.1);
      }

      50% {
        -webkit-transform: scale(1.1, 0.9);
        transform: scale(1.1, 0.9);
      }

      75% {
        -webkit-transform: scale(0.95, 1.05);
        transform: scale(0.95, 1.05);
      }
    }

    @-webkit-keyframes gelatine {

      from,
      to {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
      }

      25% {
        -webkit-transform: scale(0.9, 1.1);
        transform: scale(0.9, 1.1);
      }

      50% {
        -webkit-transform: scale(1.1, 0.9);
        transform: scale(1.1, 0.9);
      }

      75% {
        -webkit-transform: scale(0.95, 1.05);
        transform: scale(0.95, 1.05);
      }

      from,
      to {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
      }

      25% {
        -webkit-transform: scale(0.9, 1.1);
        transform: scale(0.9, 1.1);
      }

      50% {
        -webkit-transform: scale(1.1, 0.9);
        transform: scale(1.1, 0.9);

      }

      75% {
        -webkit-transform: scale(0.95, 1.05);
        transform: scale(0.95, 1.05);
      }
    }

  }

  .num {
    width: 100px;
    text-align: center;
    margin-left: -44px;
    font-size: 25px;
  }
</style>