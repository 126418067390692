<template>
  <div class="contontaab">
    <div :style="{ width: '500px', height: '297px' }" ref="myChart"></div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        Timer: ''

      }
    },
    methods: {

      getEchartData() {
        let myChart = this.$echarts.init(this.$refs.myChart);
        // 图表基础配置start
        const getSeriesData = (datalist = []) => {
          let data = datalist,
            offsetData = [],
            symbolSizeData = [];
          //计算offsetData和symbolSizeData
          symbolSizeData = [70, 70, 70, 70, 70, 70];
          // symbolSizeData = [170, 145, 200, 140, 135];
          offsetData = [
            [1, 35],
            [20, 69],
            [41, 45],
            [62, 70],
            [77, 30],
            [98, 32],
          ];
          let datas = [];
          for (let i = 0; i < data.length; i++) {
            let item = data[i];
            let itemName = "",
              nameArr = [];
            if (item && item.name) {
              itemName = item.name;
              nameArr = [];
            }
            if (itemName.length > 6) {
              nameArr.push(
                `{name|${itemName.slice(0, 6)}}`,
                `{name|${itemName.slice(6, itemName.length)}}`
              );
            } else {
              nameArr.push(item.name);
            }
            let formatter = [...nameArr, `\n{value|${item.count}}`].join("\n");
            datas.push({
              value: offsetData[i],
              symbolSize: symbolSizeData[i],
              label: {
                formatter: formatter,
                align: "center",
                rich: {
                  name: {
                    color: "#FFFFFF",
                    fontSize: symbolSizeData[i] > 125 ? "10px" : "9px",
                    fontWeight: "Bold",
                    wordBreak: "break-all",
                    width: symbolSizeData[i] + "px",
                    overflow: "hidden",
                    textOverflow: "hidden",
                  },
                  value: {
                    fontSize: symbolSizeData[i] > 125 ? "15px" : "16px",
                    fontWeight: "Bold",
                    color: "#81D0E8",
                  },
                },
              },
              itemStyle: {
                color: new this.$echarts.graphic.RadialGradient(0.5, 0.45, 0.7, [{
                    offset: 0.3,
                    color: "rgba(0,215,233,0.1)",
                  },
                  {
                    offset: 1,
                    color: "rgba(0,215,233,0.8)",
                  },
                ]),
                opacity: 0.8,
                shadowColor: "#045878",
                borderWidth: 1,
                borderColor: "rgba(0, 215, 233, 1)",
                shadowBlur: 10,
                shadowOffsetX: 1,
                shadowOffsetY: 1,
              },
              emphasis: {
                label: {
                  rich: {
                    name: {
                      color: "#FFB229",
                      fontSize: symbolSizeData[i] > 125 ? "16px" : "12px",
                      fontWeight: "Bold",
                    },
                    value: {
                      fontSize: symbolSizeData[i] > 125 ? "24px" : "18px",
                      fontWeight: "Bold",
                      color: "#FFB229",
                    },
                  },
                },
              },
            });
          }
          return datas;
        };
        let datas = this.listData;
        // let datas = [{
        //     name: '城市运行感知',
        //     count: 1135
        //   },
        //   {
        //     name: '城市交通感知',
        //     count: 326
        //   },
        //   {
        //     name: '城市安全感知',
        //     count: 4588
        //   },
        //   {
        //     name: '城市环境感知',
        //     count: 581
        //   },
        //   {
        //     name: '城市建设感知',
        //     count: 86
        //   },
        // ];

        var option = {
          title: {
            text: '部分服务人员编号表',
            textAlign: 'center',
            left: '49.5%',
            textStyle: {
              color: '#ffffff',
              fontSize: 18,
              fontWeight: '600',
            },
          },
          grid: {
            show: false,
            top: 10,
            bottom: 10,
            left: 70
          },
          xAxis: [{
            gridIndex: 0,
            type: "value",
            show: false,
            min: 0,
            max: 100,
            nameLocation: "middle",
            nameGap: 5,
          }, ],
          yAxis: [{
            gridIndex: 0,
            min: 0,
            show: false,
            max: 100,
            nameLocation: "middle",
            nameGap: 30,
          }, ],
          series: [{
            type: "scatter",
            symbol: "circle",
            symbolSize: 80,
            label: {
              show: true,
              formatter: "{b}",
              color: "#fff",
              fontSize: "10",
            },

            itemStyle: {
              color: "#00acea",
            },
            emphasis: {
              itemStyle: {
                color: new this.$echarts.graphic.RadialGradient(0.5, 0.45, 0.7, [{
                    offset: 0.3,
                    color: "rgba(191, 153, 30,0.1)",
                  },
                  {
                    offset: 1,
                    color: "rgba(191, 153, 30,0.8)",
                  },
                ]),
                opacity: 0.8,
                shadowColor: "rgba(191, 153, 30,1)",
                borderWidth: 1,
                borderColor: "rgba(191, 153, 30, 1)",
                shadowBlur: 10,
                shadowOffsetX: 1,
                shadowOffsetY: 1,
              },
            },
            data: getSeriesData(datas),
          }, ],
        };

        // 图表基础配置end


        // 图表动效及点击效果start
        let timeInterval = null;
        const highlight = (option, datalist) => {
          app.currentIndex = 0;
          timeInterval = setInterval(async () => {
            let dataLen = option.series[0].data.length;
            //取消之前高亮的图形
            myChart.dispatchAction({
              type: "downplay",
              seriesIndex: 0,
              dataIndex: app.currentIndex,
            });
            app.currentIndex = (app.currentIndex + 1) % dataLen;
            //高亮当前图形
            myChart.dispatchAction({
              type: "highlight",
              seriesIndex: 0,
              dataIndex: app.currentIndex,
            });
          }, 1000);
        };
        highlight(option, datas);

        myChart.on("click", (params) => {
          if (params.seriesType == "scatter") {
            clearInterval(timeInterval);
            let {
              dataIndex
            } = params;
            selectIndex = dataIndex;
            myChart.dispatchAction({
              type: "downplay",
              seriesIndex: 0,
              dataIndex: app.currentIndex,
            });
            myChart.dispatchAction({
              type: "highlight",
              seriesIndex: 0,
              dataIndex: dataIndex,
            });
            app.currentIndex = dataIndex;
            startTimeout();
          }
        });

        const startTimeout = () => {
          setTimeout(() => {
            if (selectIndex == app.currentIndex) {
              restartInterval();
            } else {
              startTimeout();
            }
          }, 2000);
        };

        const restartInterval = () => {
          timeInterval = setInterval(async () => {
            let dataLen = datas.length;
            //取消之前高亮的图形
            myChart.dispatchAction({
              type: "downplay",
              seriesIndex: 0,
              dataIndex: app.currentIndex,
            });
            app.currentIndex = (app.currentIndex + 1) % dataLen;
            //高亮当前图形
            myChart.dispatchAction({
              type: "highlight",
              seriesIndex: 0,
              dataIndex: app.currentIndex,
            });
          }, 1000);
        };

        // 图表动效及点击效果end
        myChart.setOption(option);
      },
      selectUserMessage() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('/artificer/selectArtificerList?page=' + 1 + '&limit=' + 20 +
          '&artificerName=&massageTypeId=&longitude=&latitude=&city=&classifyId=', {
            headers: {
              'token': Authorization
            }
          }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push('/')
              
          } else {
            let ress = res.data.data.list
            this.userNum = ress
            var arrNew = []
            arrNew = this.userNum.map((item) => {
              return {
                name: item.artificerName, //服务人员用户
                count: item.userId //价格
              }
            })
            this.listData = arrNew
            this.getEchartData()
          }

        })
      },
    },
    mounted() {
      this.getEchartData()
      this.selectUserMessage()
      this.Timer = setInterval(this.selectUserMessage, 20000)
    },
    destroyed() {
      clearInterval(this.Timer)
    }
  }
</script>

<style scoped>
  .contontaab {
    z-index: 999;
    width: 100%;
    height: 100%;
  }
</style>