import VueRouter from 'vue-router';
import loginName from '@/pages/login.vue';
import homeName from '@/pages/home.vue'
import Vue from "vue";

Vue.use(VueRouter);

const routes = [{
    path: '/',
    name: 'loginName',
    component: loginName,
    meta: {
      needLogin: true //需要加校检判断的路由
    },
  },
  {
    path: '/home',
    name: 'homeName',
    component: homeName,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});


// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    next();
  } else {
    let token = window.sessionStorage.getItem("Authorization");
    console.log(token)
    if (token === null || token === '') {
      next({
        name: 'loginName'
      });
    } else {
      next();
    }
  }
});

export default router;
